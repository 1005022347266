import { DealerDataApiParams } from '../types';
import { GetDealerDataResponse } from '../ServerApi';
import {
  ServeBannersRequest,
  ServeBannersResponse,
} from '@wix/ambassador-dealer-v1-banner/types';
import { serveBanners } from '@wix/ambassador-dealer-v1-banner/http';
import { convertBannerToBannerData } from '@wix/dealer-common';
import { HttpClientApi } from '../HttpsClientApi/HttpClient';
import { getBannersServiceProxyUrl } from '../HttpsClientApi/services-url';
import { ExternalDomains, isDeviantArtDomain } from '../externalDomains';

export const BannersApi = {
  getBanners: async (
    params: DealerDataApiParams,
  ): Promise<GetDealerDataResponse[]> => {
    if (isDeviantArtDomain(params.additionalParams)) {
      return BannersApi.getBannersFromBannersServiceProxy(
        params,
        ExternalDomains.DEVIANT_ART,
      );
    }
    return BannersApi.getBannersFromBannersService(params);
  },

  getBannersFromBannersService: async (
    params: DealerDataApiParams,
  ): Promise<GetDealerDataResponse[]> => {
    const request = BannersApi.getServeBannersRequest(params);

    const { data } = await HttpClientApi.doRequest<
      ServeBannersRequest,
      ServeBannersResponse
    >(
      serveBanners,
      request,
      params.signedInstance ? { Authorization: params.signedInstance } : {},
    );
    return BannersApi.ServeBannersResponseToDealerDataResponse(data);
  },

  getBannersFromBannersServiceProxy: async (
    params: DealerDataApiParams,
    domain: string,
  ): Promise<GetDealerDataResponse[]> => {
    const request = BannersApi.getServeBannersRequest(params);

    const url = getBannersServiceProxyUrl(domain);
    const { data } = await HttpClientApi.doGet<ServeBannersResponse>(
      url,
      request,
      params.signedInstance ? { Authorization: params.signedInstance } : {},
    );
    return BannersApi.ServeBannersResponseToDealerDataResponse(data);
  },

  ServeBannersResponseToDealerDataResponse: (
    data: ServeBannersResponse,
  ): GetDealerDataResponse[] => {
    if (!data || !data.banners || data.banners.length === 0) {
      return [];
    }

    return data.banners.map((banner) => {
      const bannerData = convertBannerToBannerData(banner);
      return {
        asset: bannerData,
        ...bannerData,
      };
    });
  },

  getServeBannersRequest: (
    params: DealerDataApiParams,
  ): ServeBannersRequest => {
    const request: ServeBannersRequest = {
      placementId: params.bannerPositions[0],
    };
    if (params.limit) {
      request.limit = params.limit;
    }
    if (params.excludeLightboxes) {
      request.excludeOfferIds = params.excludeLightboxes;
    }
    if (!params.signedInstance && params.metasiteGuid) {
      request.metaSiteId = params.metasiteGuid;
    }
    if (params.additionalParams?.deviantArtUserId) {
      request.deviantArtUserId = params.additionalParams.deviantArtUserId;
    }
    return request;
  },
};
