import {
  HttpClient,
  HttpResponse,
  RequestOptionsFactory,
} from '@wix/http-client';

const httpClient = new HttpClient();

export const HttpClientApi = {
  doRequest<TRequest = any, TResponse = any>(
    ambassadorRequest: (params: TRequest) => RequestOptionsFactory<TResponse>,
    params: TRequest,
    headers?: { [key: string]: string },
  ): Promise<HttpResponse<TResponse>> {
    return httpClient.request((ctx: any) => {
      return {
        ...ambassadorRequest(params)(ctx),
        headers,
      };
    });
  },

  doPost<TResponse = any, TParams = any>(
    url: string,
    params: TParams,
    headers?: { [key: string]: string },
  ): Promise<HttpResponse<TResponse>> {
    return httpClient.post(url, params, {
      headers,
    });
  },

  doGet<TResponse = any, TParams = any>(
    url: string,
    params: TParams,
    headers?: { [key: string]: string },
  ): Promise<HttpResponse<TResponse>> {
    return httpClient.get(url, {
      params,
      headers,
    });
  },
};
