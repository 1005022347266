import Experiments from '@wix/wix-experiments';
import { reportSentryError } from '@wix/dealer-common/dist/src/services';
import {
  DealerDataApiParams,
  DealerOfferApiParams,
  DealerOfferResponse,
  ExperimentsBag,
  ReportOfferEventsParams,
} from '../types';
import { GetDealerDataResponse } from './common';
import { BannerData } from '@wix/dealer-common/dist/src/interfaces';
import { ListOffersRequest, ListOffersResponse } from '../BannersApi/types';
import { HttpClientApi } from '../HttpsClientApi/HttpClient';
import { BannersApi } from '../BannersApi/BannersApi';
import {
  OFFERS_SERVING,
  REPORT_EVENT_URL,
} from '../HttpsClientApi/services-url';

export interface DealerDataApiResponse {
  dealerData: { [position: string]: BannerData[] };
  experiments: ExperimentsBag;
}

export interface GetNewDealerDataResponse {
  banners: GetDealerDataResponse[];
  experiments: ExperimentsBag;
}

export const ServerApi = {
  getDealerData: async (
    params: DealerDataApiParams,
  ): Promise<GetDealerDataResponse> => {
    const newParams = { ...params, limit: 1 };
    const { experiments, banners } = await ServerApi.getDealerDataNew(
      newParams,
    );
    const bannerData = banners[0] || {};

    const finalBannerData = {
      ...bannerData,
      experiments,
    };

    if (!finalBannerData.asset) {
      throw new Error('empty-data-dealer-content');
    }

    return finalBannerData;
  },

  getDealerDataNew: async (
    params: DealerDataApiParams,
  ): Promise<GetNewDealerDataResponse> => {
    const experimentsLoader = new Experiments();
    await experimentsLoader.load('dealer-client');
    const experimentsPromise = experimentsLoader
      .ready()
      .then(() => {
        return experimentsLoader.all();
      })
      .catch(() => ({}));

    const experiments = (await experimentsPromise) as ExperimentsBag;

    const banners = await BannersApi.getBanners(params);

    const response: GetNewDealerDataResponse = {
      experiments,
      banners,
    } as GetNewDealerDataResponse;
    return response;
  },

  getOffersData: (
    { excludeAssetIds = [], realEstateId }: DealerOfferApiParams,
    headers: { [key: string]: string },
  ) => {
    const req: ListOffersRequest = {
      excludeOfferIds: excludeAssetIds,
      realEstateId,
    };

    return HttpClientApi.doGet<ListOffersResponse>(
      OFFERS_SERVING,
      req,
      headers,
    );
  },

  reportOfferEvent(params: ReportOfferEventsParams) {
    const { request, signedInstance } = params;

    if (!request.event.offerId) {
      return Promise.resolve({});
    }

    const headers = signedInstance ? { Authorization: signedInstance } : {};

    return HttpClientApi.doPost<DealerOfferResponse>(
      REPORT_EVENT_URL(request.event.offerId),
      request,
      headers,
    ).catch((e) => {
      reportSentryError(e, {
        realEstateId: request.event.realEstateId,
        eventType: request.event.type,
        offerId: request.event.offerId,
        dealerApiType: 'events',
        reportingClass: 'ServerApi.reportOfferEvent',
      });
    });
  },
};
